define("discourse/plugins/discourse-layouts/discourse/initializers/sidebars", ["exports", "discourse-common/utils/decorators", "@ember/object/computed", "@ember/controller", "@ember/service", "discourse/plugins/discourse-layouts/discourse/lib/layouts", "discourse/lib/plugin-api"], function (_exports, _decorators, _computed, _controller, _service, _layouts, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const PLUGIN_ID = "discourse-layouts";
  var _default = _exports.default = {
    name: "sidebars",
    initialize(container) {
      const site = container.lookup("site:main");
      const siteSettings = container.lookup("site-settings:main");
      const router = container.lookup("router:main");
      if (!siteSettings.layouts_enabled || site.mobileView && !siteSettings.layouts_mobile_enabled) {
        return;
      }
      (0, _layouts.setupContexts)();
      router.on("routeDidChange", transition => {
        if (!transition.from) {
          return;
        }
        const routeInfos = transition.router.currentRouteInfos;
        const routeNames = routeInfos.map(ri => ri.name);
        let changedToContext;
        routeNames.forEach(routeName => {
          let routeContext = (0, _layouts.getContextFromAttr)(routeName, "route");
          if (routeContext) {
            changedToContext = (0, _layouts.normalizeContext)(routeContext);
          }
        });
        if (!changedToContext) {
          let classes = document.body.className.split(" ").filter(c => !c.startsWith(`${_layouts.layoutsNamespace}-`));
          document.body.className = classes.join(" ").trim();
        }
      });
      (0, _pluginApi.withPluginApi)("0.8.32", api => {
        var _dec, _obj, _dec2, _obj2, _dec3, _obj3, _dec4, _obj4;
        api.modifyClass("controller:discovery/list", (_dec = (0, _decorators.default)("navigationDefault.filterType", "navigationCategory.filterType", "currentPath"), (_obj = {
          pluginId: PLUGIN_ID,
          router: (0, _service.inject)(),
          currentPath: (0, _computed.readOnly)("router.currentRouteName"),
          navigationDefault: (0, _controller.inject)("navigation/default"),
          navigationCategory: (0, _controller.inject)("navigation/category"),
          sidebarFilter(defaultFilter, categoryFilter, currentPath) {
            if (!currentPath) {
              return undefined;
            }
            let path = currentPath.toLowerCase();
            if (path.indexOf("categories") > -1) {
              return "categories";
            }
            if (path.indexOf("category") > -1) {
              return categoryFilter;
            }
            return defaultFilter;
          }
        }, (_applyDecoratedDescriptor(_obj, "sidebarFilter", [_dec], Object.getOwnPropertyDescriptor(_obj, "sidebarFilter"), _obj)), _obj)));
        api.modifyClass("controller:discovery/categories", (_dec2 = (0, _decorators.default)("navigationDefault.filterType", "navigationCategory.filterType", "currentPath"), (_obj2 = {
          pluginId: PLUGIN_ID,
          router: (0, _service.inject)(),
          currentPath: (0, _computed.readOnly)("router.currentRouteName"),
          navigationDefault: (0, _controller.inject)("navigation/default"),
          navigationCategory: (0, _controller.inject)("navigation/category"),
          sidebarFilter(defaultFilter, categoryFilter, currentPath) {
            if (!currentPath) {
              return undefined;
            }
            let path = currentPath.toLowerCase();
            if (path.indexOf("categories") > -1) {
              return "categories";
            }
            if (path.indexOf("category") > -1) {
              return categoryFilter;
            }
            return defaultFilter;
          }
        }, (_applyDecoratedDescriptor(_obj2, "sidebarFilter", [_dec2], Object.getOwnPropertyDescriptor(_obj2, "sidebarFilter"), _obj2)), _obj2)));
        api.modifyClass("controller:discovery/filter", (_dec3 = (0, _decorators.default)("navigationDefault.filterType", "navigationCategory.filterType", "currentPath"), (_obj3 = {
          pluginId: PLUGIN_ID,
          router: (0, _service.inject)(),
          currentPath: (0, _computed.readOnly)("router.currentRouteName"),
          navigationDefault: (0, _controller.inject)("navigation/default"),
          navigationCategory: (0, _controller.inject)("navigation/category"),
          sidebarFilter(defaultFilter, categoryFilter, currentPath) {
            if (!currentPath) {
              return undefined;
            }
            let path = currentPath.toLowerCase();
            if (path.indexOf("categories") > -1) {
              return "categories";
            }
            if (path.indexOf("category") > -1) {
              return categoryFilter;
            }
            return defaultFilter;
          }
        }, (_applyDecoratedDescriptor(_obj3, "sidebarFilter", [_dec3], Object.getOwnPropertyDescriptor(_obj3, "sidebarFilter"), _obj3)), _obj3)));
        api.modifyClass("controller:topic", {
          pluginId: `${PLUGIN_ID}-v2`,
          category: (0, _computed.alias)("model.category"),
          userHideRightSidebar: false
        });
        api.modifyClass("component:topic-navigation", (_dec4 = (0, _decorators.observes)("controller.hasRightSidebar"), (_obj4 = {
          pluginId: PLUGIN_ID,
          controller: (0, _controller.inject)("topic"),
          sidebarsUpdated() {
            this._performCheckSize();
          },
          _performCheckSize() {
            if (!this.element || this.isDestroying || this.isDestroyed) {
              return;
            }
            const hasRightSidebar = this.controller.get("hasRightSidebar");
            if (hasRightSidebar && !this.info.get("topicProgressExpanded")) {
              const info = this.get("info");
              info.setProperties({
                renderTimeline: false,
                renderAdminMenuButton: true
              });
            } else {
              this._super(...arguments);
            }
          }
        }, (_applyDecoratedDescriptor(_obj4, "sidebarsUpdated", [_dec4], Object.getOwnPropertyDescriptor(_obj4, "sidebarsUpdated"), _obj4)), _obj4)));
      });
    }
  };
});